export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const azureB2CConfigProd = {
  tenant: 'extensionmd',
  userFlowPolicy: 'B2C_1_WebSignIn',
  clientId: '',
  cacheLocation: 'sessionStorage',
  scopes: [ "https://extensionmd.onmicrosoft.com/api/offline_access", "offline_access" ],
  forgotPasswordAuthority: "https://extensionmd.b2clogin.com/extensionmd.onmicrosoft.com/B2C_1_ForgotPassword",
  patientSignupAuthority: "https://extensionmd.b2clogin.com/extensionmd.onmicrosoft.com/B2C_1A_patient_signup",

  msalConfig: {
    auth: {
      clientId: "b3e6339d-37e6-432c-9050-acb22ffbc302",
      authority: "https://extensionmd.b2clogin.com/extensionmd.onmicrosoft.com/B2C_1_WebSignIn",
      knownAuthorities: [ 'extensionmd.b2clogin.com' ],
      postLogoutRedirectUri: 'https://www.extensionmd.com'
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
    },
  }
};

export const azureB2CConfigDev = {
  tenant: 'xmdtest',
  userFlowPolicy: 'B2C_1_WebSignIn',
  clientId: '',
  cacheLocation: 'sessionStorage',
  scopes: [ "https://xmdtest.onmicrosoft.com/api/offline_access", "offline_access" ],
  forgotPasswordAuthority: "https://xmdtest.b2clogin.com/xmdtest.onmicrosoft.com/B2C_1_ForgotPassword",

  msalConfig: {
    auth: {
      clientId: "c126887a-f64e-4f1e-88fc-b1820180a2f2",
      authority: "https://xmdtest.b2clogin.com/xmdtest.onmicrosoft.com/B2C_1_WebSignIn",
      knownAuthorities: [ 'xmdtest.b2clogin.com' ],
      postLogoutRedirectUri: 'https://www.extensionmd.com'
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
    },
  }
};
