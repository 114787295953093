import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Logo from 'src/components/Logo';
import {
  AppBar,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import LogoutButton from 'src/components/LogoutButton';
  
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  appbar: {
    backgroundColor: '#ffffff'
  },
  toolbar: {
    background: "linear-gradient(90deg, #B558FE, #7133FE)",
    minHeight: 74
  },
  wrapper: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 74,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  extension: {
    color: "#f1268f",
    fontFamily: 'Montserrat',
    fontSize: '35pt',
    fontWeight: '300',
  },
  md: {
    color: "#c273eb",
    fontFamily: 'Montserrat',
    fontSize: '35pt',
    fontWeight: '300',
  },
  logoutWrapper: {
    marginLeft: 'auto',
    marginRight: 0
  }
}));

const XMDLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={clsx(classes.appbar)}>
        <Toolbar className={classes.toolbar}>
          <Logo />
          <div className={classes.logoutWrapper}>
            <LogoutButton />
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

XMDLayout.propTypes = {
  children: PropTypes.node
};

export default XMDLayout;

