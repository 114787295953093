import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "22px",
    [theme.breakpoints.up("md")]: {
      height: "26px",
    },
  },
}));

const Logo = (props) => {
  const classes = useStyles(); 

  return (
    <img
      className={classes.root}
      alt="Logo"
      src="/static/xmd-logo-white.png"
      {...props}
    />
  );
}

export default Logo;
