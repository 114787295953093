import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import XMDLayout from 'src/layouts/XMDLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import PatientGuard from 'src/components/PatientGuard';
import ProviderGuard from 'src/components/ProviderGuard';
  

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('src/views/auth/Login'))
  },
  {
    exact: true,
    path: '/logout',
    component: lazy(() => import('src/views/auth/Logout'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/Login'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: PatientGuard,
    path: '/patient',
    component: lazy(() => import('src/views/account/PatientView'))
  },
  {
    exact: true,
    path: '/patient-signup/:inviteId',
    layout: XMDLayout,
    component: lazy(() => import('src/views/account/PatientSignup'))
  },
  {
    exact: true,
    path: '/patient-info',
    layout: XMDLayout,
    component: lazy(() => import('src/views/account/PatientInfo'))
  },
  {
    exact: true,
    path: '/patient-signup-verify',
    layout: XMDLayout,
    component: lazy(() => import('src/views/account/PatientSignup/ShipVerify'))
  },
  {
    exact: true,
    path: '/patient-signup-success',
    layout: XMDLayout,
    component: lazy(() => import('src/views/account/PatientSignup/SignupSuccess'))
  },
  {
    exact: true,
    path: '/clinicians-coming-soon',
    layout: XMDLayout,
    component: lazy(() => import('src/views/comingsoon/clinicians'))
  },
  {
    exact: true,
    path: '/reports-coming-soon',
    layout: XMDLayout,
    component: lazy(() => import('src/views/comingsoon/reports'))
  },
  {
    exact: true,
    path: '/provider-signup',
    layout: XMDLayout,
    component: lazy(() => import('src/views/account/ProviderSignup'))
  },
  {
    exact: true,
    path: '/provider-info',
    layout: XMDLayout,
    component: lazy(() => import('src/views/account/ProviderInfo'))
  },
  {
    exact: true,
    path: '/provider-patients',
    layout: XMDLayout,
    component: lazy(() => import('src/views/Provider/Home'))
  },
  {
    exact: true,
    path: '/provider-signup-success',
    layout: XMDLayout,
    component: lazy(() => import('src/views/account/ProviderSignup/success'))
  },
  {
    exact: true,
    path: '/invite-start',
    layout: XMDLayout,
    component: lazy(() => import('src/views/invite/start'))
  },
  {
    exact: true,
    path: '/invite-patients',
    layout: XMDLayout,
    component: lazy(() => import('src/views/invite/getemails'))   
  },
  {
    exact: true,
    path: '/invite-note',
    layout: XMDLayout,
    component: lazy(() => import('src/views/invite/getnote'))   
  },
  {
    exact: true,
    path: '/invite-preview',
    layout: XMDLayout,
    component: lazy(() => import('src/views/invite/preview'))   
  },
  {
    exact: true,
    path: '/invite-success',
    layout: XMDLayout,
    component: lazy(() => import('src/views/invite/success'))   
  },
  {
    exact: true,
    path: '/edit-patient/:patientId',
    layout: XMDLayout,
    component: lazy(() => import('src/views/PatientEdit')) 
  },
  {
    exact: true,
    path: '/signup-success',
    layout: XMDLayout,
    component: lazy(() => import('src/views/account/PatientSignup/SignupSuccess'))
  },
  {
    exact: true,
    path: '/app/patient/:patientId',
    guard: ProviderGuard,
    layout: XMDLayout,
    component: lazy(() => import('src/views/PatientDetail'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/readings',
        guard: ProviderGuard,
        component: lazy(() => import('src/views/reports/ReadingView'))
      },
      {
        exact: true,
        path: '/app/patientlist',
        guard: ProviderGuard,
        component: lazy(() => import('src/views/PatientList'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/readings" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: XMDLayout,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
