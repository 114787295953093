import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const PatientGuard = ({ children }) => {
  const { isInitialised, isAuthenticated, loginRedirect, tokenPending } = useAuth();

  if(!isInitialised || tokenPending) {
    return null;
  }

  if (!isAuthenticated) {
    console.log('loginRedirect from PatientGuard');
    loginRedirect();
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

PatientGuard.propTypes = {
  children: PropTypes.node
};

export default PatientGuard;
