export default {
  fontFamily: 'Comfortaa',
  h1: {
    fontWeight: 700,
    fontSize: 20,
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
  },
  h2: {
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
  },
  h3: {
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
  },
  h4: {
    fontWeight: 400,
    fontSize: 20,
    letterSpacing: '0.28px',
    textTransform: 'uppercase',
  },
  h5: {
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: '0.28px',
    textTransform: 'uppercase',    
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px'
  },
  body1: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.28px'   
  },
  overline: {
    fontWeight: 500
  }
};
