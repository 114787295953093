import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import LoadingScreen from 'src/components/LoadingScreen';

const GuestGuard = ({ children }) => {
  const { isInitialised, isAuthenticated, tokenPending } = useAuth();
  console.log("GuestGuard window.location.href=", window.location.href);

  if(!isInitialised || tokenPending) {
    console.log("GuestGuard returning LoadingScreen isInitialized tokenPending", isInitialised, tokenPending);
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    console.log("GuestGuard: authenticated. redirecting to /app/account");
    return <Redirect to="/app/account" />;
  }

  console.log("GuestGuard returning Children");
  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
