import React from 'react';
import {
  Button,
  makeStyles,
  Typography
} from '@material-ui/core';
import { AccountCircleOutlined } from '@material-ui/icons';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #9B51E0",
    backgroundColor: "#DDE5E8",
    color: "#5850EC",
    paddingTop: "3px",
    paddingBottom: "3px",
    '&:hover': {
      backgroundColor: "#BDC5C8"
    }
  },
  logoutText: {
    fontSize: "10px",
    fontWeight: "700",
    paddingLeft: "8px",
    paddingRight: "8px",
    letterSpacing: "0.1em"
  }
}));

const LogoutButton = () => {
  const classes = useStyles();
  const { logout } = useAuth(); 

  return (
    <Button
      className={ classes.root }
      onClick={() => logout()}
    >
      <Typography className={classes.logoutText}>Logout</Typography>
      <AccountCircleOutlined  />
    </Button>
  )
}

export default LogoutButton;
