import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import LoadingScreen from 'src/components/LoadingScreen';

const AuthGuard = ({ children }) => {
  const { isInitialised, isAuthenticated, tokenPending } = useAuth();
  console.log("AuthGuard window.location.href=", window.location.href);

  if(!isInitialised || tokenPending) {
    console.log("Authguard returning LoadingScreen isInitialized tokenPending", isInitialised, tokenPending);
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    console.log("AuthGuard: !authenticated. redirecting");
    return <Redirect to="/login" />;
  }

  console.log("AuthGuard returning Children");
  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
