import React, {
    createContext,
    useEffect,
    useCallback,
    useReducer
  } from 'react';
  import * as Msal from '@azure/msal-browser';
  import SplashScreen from 'src/components/SplashScreen';
  import { azureB2CConfigProd, azureB2CConfigDev } from 'src/config';
  import axios from 'src/utils/axiosReal';

  const azureB2CConfig = (process.env.NODE_ENV === "development") ? azureB2CConfigDev : azureB2CConfigProd;

  let msalClient = null;
  let accountId = null;
  
  const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    tokenPending: false,
    usertype: null,
    user: null,
    dbUser: {}
  };

  const setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };


  const reducer = (state, action) => {
    let newState = null;
    switch (action.type) {
      case 'INITIALISE': {
        newState = {
          ...state,
          tokenPending: true,
          isInitialised: true
        };
        break;
      }
      case 'NOLOGIN': {
        newState = {
          ...state,
          tokenPending: false,
        };
        break;
      }
      case 'SILENTLOGINAUTH': {
        newState = {
          ...state,
          tokenPending: true,
          isInitialised: true
        };
        break;
      }
      case 'SILENTLOGINFAIL': {
        newState = {
          ...state,
          tokenPending: false,
        };
        break;
      }
      case 'LOGINPENDING': {
        const { user } = action.payload;
        newState = {
          ...state,
          tokenPending: true,
          user
        };
        break;
      }
      case 'LOGIN': {
        const { user } = action.payload;
        newState = {
          ...state,
          user
        };
        break;
      }
      case 'GOTUSERDATA': {
        const { usertype, dbUser } = action.payload;
        newState = {
          ...state,
          tokenPending: false,
          isAuthenticated: true,
          usertype: usertype,
          dbUser: dbUser,
        };
        break;
      }
      case 'LOGOUT': {
        newState = {
          ...state,
          isAuthenticated: false,
          tokenPending: false,
          user: null,
          dbUser: {}
        };
        break;
      }
      default: {
        newState = state;
        break;
      }
    }
    console.log(action.type, newState);
    return newState;
};
  
  const AuthContext = createContext({
    ...initialAuthState,
    method: 'AzureB2C',
    loginRedirect: () => { },
    logout: () => { },
    createUserWithEmailAndPassword: () => { }
  });
  
  export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
  
    const loginRedirect = (options) => {
      console.log("msalClient.loginRedirect(loginRedirect)");
      msalClient.loginRedirect({ scopes: azureB2CConfig.scopes, ...options });
    };

    const logout = () => {
//  Don't dispatch logout here because if we do, then the page will rerender, and the AuthGuard will
//  immediately redirect us back to login, cancelling the redirect to logout below.
//  Since we're redirecting away, we should be logged out when we come back.
//      dispatch({
//        type: 'LOGOUT'
//      });
      setSession(null);
      console.log("msalClient.logout");
      msalClient.logout();  
    };

    const getUserData = useCallback(async () => {
      try {
        let usertype = "patient";
        let user;
  
        try {
          const response = await axios.get('/api/me');
          user = response.data;
          usertype = response.data.type;
          console.log("getUserData", response.data);
        } catch (err) {
          console.log(err);
          console.log("getUserData failed.  Probably new user.");
          user = {};
          usertype = "";
        }

        console.log("about to dispatch GOTUSERDATA", usertype);
        dispatch({
          type: 'GOTUSERDATA',
          payload: {
            usertype: usertype,
            dbUser: user,
          }
        });

      } catch (err) {
        console.error(err);
      }
    }, []); 
  
    const createUserWithNameEmailAndPassword = async (name, email, password, usertype, inviteId) => {
      let values = { name: name, email: email, password: password, usertype: usertype, inviteId: inviteId };
      let result = await axios.post('/api/patient', values);
      console.log("CreateUser result", result.data);
      let account = { 
        authorityType: "MSSTS", 
        environment: result.data.environment, 
        homeAccountId: result.data.homeAccountId,
        localAccountId: result.data.localAccountId,
        name: result.data.name,
        realm: "",
        username: result.data.username
      };
      window["sessionStorage"][`${result.data.homeAccountId}-${result.data.environment}-`] = JSON.stringify(account);
      let idToken = {
        credentialType: "IdToken",
        homeAccountId: result.data.homeAccountId,
        environment: result.data.environment, 
        clientId: azureB2CConfig.msalConfig.auth.clientId,
        secret: result.data.idToken,
        realm: "",
      };
      window["sessionStorage"][`${result.data.homeAccountId}-${result.data.environment}-idtoken-${idToken.clientId}--`] = JSON.stringify(idToken);
      let accessToken = {
        credentialType: "AccessToken",
        homeAccountId: result.data.homeAccountId,
        environment: result.data.environment, 
        clientId: azureB2CConfig.msalConfig.auth.clientId,
        secret: result.data.accessToken,
        realm: "",
        expiresOn: result.data.expiresOn,
        extendedExpiresOn: result.data.expiresOn,
        target: result.data.scope,
        tokenType: "Bearer",
      };
      window["sessionStorage"][`${result.data.homeAccountId}-${result.data.environment}-accesstoken-${idToken.clientId}--${result.data.scope}`] = JSON.stringify(accessToken);
      let token = result.data.accessToken;
      localStorage.setItem('accountId', result.data.homeAccountId);
      setSession(token);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
              accessToken: token
          }
        }
      });
      getUserData();
    };

    const RefreshUserData = () => {
      console.log("RefreshUserData");
      getUserData();
    }
  
    useEffect(() => {
      const HandleResponse = (response) => {
          console.log("HandleResponse", response);
          if(response !== null) {
              accountId = response.account.homeAccountId;
              localStorage.setItem('accountId', accountId);
          
              // We need to reject id tokens that were not issued with the default sign-in policy.
              // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
              if (response.tokenType === "id_token" && response.idToken.claims['tfp'] === 'B2C_1_ResetPassword') {
                console.log("msalClient.logout");
                msalClient.logout();
                localStorage.removeItem('accountId');
                window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
          
              } else if(response.tokenType === "id_token") {
                console.log("id_token acquired at: " + new Date().toString());

                let account = msalClient.getAccountByHomeId(accountId);
        
                const isAuthenticated = (account != null);     
        
                if (isAuthenticated) {
        
                  dispatch({
                    type: 'LOGINPENDING',
                    payload: {
                      user: {
                          id: account.accountIdentifier,
                          name: account.name,
                      }
                    }
                  });                
                }
              } else if (response.tokenType === "access_token") {
                setSession(response.accessToken);
                let account = msalClient.getAccountByHomeId(accountId);
                const name = account.name ?? (account.idTokenClaims.given_name + " " + account.idTokenClaims.family_name);
                dispatch({
                  type: 'LOGIN',
                  payload: {
                    user: {
                        id: account.accountIdentifier,
                        name: name,
                        email: account.idTokenClaims.emails[0],
                        accessToken: response.accessToken
                    }
                  }
                });
                console.log("calling getUserData from authRedirectCallback");
                getUserData();
              } else if(response.tokenType === "Bearer") {
                console.log("bearer acquired at: " + new Date().toString());
                HandleBearerToken(response.accessToken);
                return;

              } else {
                console.log("unknown state", response);
              }
          }

          console.log("doSilentLogin");
          SilentLogin();
      };

      const HandleBearerToken = (token) => {
        setSession(token);

        let account = msalClient.getAccountByHomeId(accountId);

        // Get values from the account

        // Get Name
        const name = account.name ?? (account.idTokenClaims.given_name + " " + account.idTokenClaims.family_name);

        // Get Email
        let email = "";
        try {
          let whatever = account.idTokenClaims["Verified.Email"];
          if(whatever != null) {
            email = whatever;
          } else {
            email = account.idTokenClaims.emails[0];
          }
          console.log("email=", email);
        } catch (e)
        {
          console.log("Unable to get email. account=", account);
        }

        // Update state to Login
        console.log("about to dispatch LOGIN");
        dispatch({
          type: 'LOGIN',
          payload: {
            user: {
                id: account.accountIdentifier,
                name: name,
                email: email,
                tier: 'Premium',
                accessToken: token
            }
          }
        });
        console.log("back from dispatch LOGIN");
        getUserData();
      };

      const SilentLogin = () => {
        let account = msalClient.getAccountByHomeId(accountId);
        console.log("SilentLogin Account", account);
        const isAuthenticated = (account != null);     

        if (isAuthenticated) {
          dispatch({ type: 'SILENTLOGINAUTH' });
          console.log("msalClient.acquireTokenSilent");
          msalClient.acquireTokenSilent({ scopes: azureB2CConfig.scopes, forceRefresh: false,  account: accountId }).then(accessToken => {
            console.log("msalClient.acquireTokenSilent.then");
            HandleBearerToken(accessToken.accessToken);
          }, error => {
            if (error instanceof Msal.InteractionRequiredAuthError) {
              console.log("InteractionRequiredAuthError.  Redirecting...");
              // fallback to interaction when silent call fails
              msalClient.acquireTokenRedirect({ scopes: azureB2CConfig.scopes});
            } else {
              dispatch({ type: 'SILENTLOGINFAIL' });
              console.log(error);  
            }
          });     
        } else {
          dispatch({ type: 'NOLOGIN' });
        }
      };

      const loggerCallback = (level, message, containsPii) => {
//              if (containsPii) {	
//                  return;	
//              }	
            switch (level) {	
                case Msal.LogLevel.Error:	
                    console.error(message);	
                    return;	
                case Msal.LogLevel.Info:	
                    console.info(message);	
                    return;	
                case Msal.LogLevel.Verbose:	
                    console.debug(message);	
                    return;	
                case Msal.LogLevel.Warning:	
                    console.warn(message);	
                    return;	
                default:
                  return;
            }
        };



      const initialise = async () => {
        try {
          accountId = localStorage.getItem('accountId');
          let _redirectUri = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`;
          let auth = azureB2CConfig.msalConfig.auth;
          let config = 
          {
            auth: {
              redirectUri: _redirectUri,
              ...auth
            },
            cache: {
              ...azureB2CConfig.msalConfig.cache
            },
            system: {
              loggerOptions: { 
                loggerCallback: loggerCallback 
              }
            }
          }
          msalClient = new Msal.PublicClientApplication(config);

          dispatch({ type: 'INITIALISE' });

          console.log("msalClient.handleRedirectPromise");
          msalClient.handleRedirectPromise()
            .then(HandleResponse)
            .catch((error, a) => {
                // Handle Forgot Password case
                if(error.errorMessage.indexOf("AADB2C90118") > -1) {
                  try {
                    console.log("Forgot password authority:", azureB2CConfig.forgotPasswordAuthority);
                    msalClient.loginRedirect({ authority: azureB2CConfig.forgotPasswordAuthority });
                  } catch (err) {
                    console.log(err);
                  }               
                } else {
                  console.log("Error from handleRedirectPromise", error);
                }
            });

        } catch (err) {
          console.error(err);
        }      
      };
  
      initialise();
    }, [getUserData]);
  
    if (!state.isInitialised || state.tokenPending) {
      return <SplashScreen />;
    }
  
    return (
      <AuthContext.Provider
        value={{
          ...state,
          method: 'AzureB2C',
          loginRedirect,
          logout,
          createUserWithNameEmailAndPassword,
          RefreshUserData
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
  
  export default AuthContext;