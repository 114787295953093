import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const ProviderGuard = ({ children }) => {
  const { isInitialised, isAuthenticated, usertype, tokenPending } = useAuth();
  console.log("ProviderGuard window.location.href=", window.location.href);

  if(!isInitialised || tokenPending) {
    console.log("ProviderGuard returning null isInitialized tokenPending", isInitialised, tokenPending);
    return null;
  }

  if (!isAuthenticated) {
    console.log("ProviderGuard redirecting to /login isAuthenticated", isAuthenticated);
    return <Redirect to="/login" />;
  }

  if(usertype !== "provider") {
      console.log("ProviderGuard redirecting to / usertype", usertype);
      return <Redirect to="/" />
  }

  return (
    <>
      {children}
    </>
  );
};

ProviderGuard.propTypes = {
  children: PropTypes.node
};

export default ProviderGuard;
